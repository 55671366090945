import "core-js/es/array/iterator";
import "lazysizes";

// Import our CSS
import styles from "../scss/phone.scss";

// Components
import Twilio from "./components/phone/Twilio.vue";

// App main
const main = async () => {
  const { default: Vue } = await import(/* webpackChunkName: "vue" */ "vue");

  // Create our vue instance
  const vm = new Vue({
    el: "#app",
    delimiters: ["${", "}"],
    components: { Twilio },
    data: {},
    methods: {},
    computed: {},
    created() {},
    destroyed() {},
    mounted() {},
  });

  return vm;
};

main().then((vm) => {});

if (module.hot) {
  module.hot.accept();
}
