import axios from "axios";

export function post(endpoint, body) {
  return axios
    .post(endpoint, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": window.sitedata.csrf,
        "X-Requested-With": "XMLHttpRequest",
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
}

export function get(endpoint) {
  return axios.get(endpoint, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": window.sitedata.csrf,
      "X-Requested-With": "XMLHttpRequest",
    },
  });
}
